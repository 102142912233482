import * as React from "react"
import { graphql } from "gatsby"
import Excerpt, {IBlogExcerpt} from "../components/blog/excerpt"
import Layout from "../components/layouts/index-layout"

interface IData {
  allMdx: {
    edges: {
      node: IBlogExcerpt
    }[]
  }
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const ByMonth = ( { data, pageContext}: { data: IData, pageContext: any}) => {
  let year = pageContext.glob.substr(1, 4)
  let month = monthNames[parseInt(pageContext.glob.substr(6, 2))-1]
  console.log("year=", year, "month=", month, "glob=", pageContext.glob)
  return (
    <Layout>
      <h1 className="text-3xl">{month} {year}</h1>
      {data.allMdx.edges.map((edge, idx) => (
        <Excerpt key={idx} node={edge.node} />
      ))}
    </Layout>
  )
}

export const query = graphql`
    query monthBlog($glob: String!) {
        allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {fields: {collection: {eq: "posts"}, slug: {glob: $glob}}}) {
            edges {
                node {
                    fields {
                        excerpt
                        tagSlugs {
                            name
                        }
                        categorySlugs {
                            name
                        }
                        slug
                    }
                    frontmatter {
                        author
                        date
                        title
                        prettyDate: date(formatString: "MMM Do, YYYY")
                    }
                }
            }
        }
    }
`

export default ByMonth